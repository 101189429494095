import { LineChart, StackedAreaChart } from "@get-dx/d3-charts";
import {
  getQueryStringParams,
  setQueryStringParam,
  objectToQueryString,
  generatePreviousComparisonValues,
  generateBenchmarkComparisonValues,
  sortFunc,
  arrayRange,
  numberToSpelling,
  snapshotSquadsFromCache,
  generateOrgComparisonValues,
} from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

const targetPositionCountMap = new Map([
  [0, "zero_position_count"],
  [1, "one_position_count"],
  [2, "two_position_count"],
  [3, "three_position_count"],
  [4, "four_position_count"],
  [5, "five_position_count"],
  [6, "six_position_count"],
]);

const workflowDiffOptionsMap = new Map([
  ["vs_prev", "prev_percentage"],
  ["vs_org", "org_score"],
  ["vs_50th", "benchmark_50"],
  ["vs_75th", "benchmark_75"],
  ["vs_90th", "benchmark_90"],
]);

function generateComparisonValues(
  resource,
  benchmark,
  resourceType = "workflow",
) {
  let comparisonValues = [];

  if (
    ["vs_prev", "prev_percentage"].includes(benchmark) &&
    resource.values.length > 1
  ) {
    comparisonValues = generatePreviousComparisonValues(resource);
  } else if (!["vs_prev", "prev_percentage"].includes(benchmark)) {
    comparisonValues = generateBenchmarkComparisonValues(
      resource,
      resourceType,
      benchmark,
    );
  }

  return comparisonValues;
}

export function snapshotWorkflows(defaults) {
  const {
    baseUrl,
    showOrgAvg,
    minResponseThreshold,
    drilldownTabs,
    maxBehavioralQuestions,
    tagOptions,
    snapshotId,
    hasHierarchyGroups,
  } = defaults;

  const targetedWorkflowId = getQueryStringParams().get(
    "behavioral_question_id",
  );

  return {
    minResponseThreshold,
    resultItemsLoaded: false,
    resultItems: [],
    responseCount: null,
    recipientCount: null,
    drilldownModalOpen: !!targetedWorkflowId,
    targetedWorkflowId,
    drilldownTabs,
    relatedDriver: null,
    trends: null,
    xStart: null,
    tabDataLoaded: false,
    maxBehavioralQuestions,
    selectedDrilldownTab: getQueryStringParams().get("ddt"),
    xEnd: null,
    selectedWorkflow: null,
    relatedDriverTrends: [],
    drilldownData: {},
    squadNamesFromSquadIds: [],
    showTeamBreakdown: true,
    init() {
      this.$root.addEventListener("dx:squadsTree:init", () => {
        this.determineSquadNamesFromSquadIds();
        // Loading directly to modal from URL cached squads are empty, so wait for event
        if (this.squadIds.length != 1) {
          this.showTeamBreakdown = true;
        } else {
          this.showTeamBreakdown = this.cachedSquads.find(
            (squad) => squad.id == this.squadIds[0],
          )?.is_parent;
        }
      });
      this.fetchAll().then(() => {
        if (this.selectedDrilldownTab) {
          const selectedItem = drilldownTabs.find(
            (t) => t.tab == this.selectedDrilldownTab,
          );
          this.fetchTab(this.selectedDrilldownTab, selectedItem.url);
        }

        if (targetedWorkflowId) {
          this.selectedWorkflow = this.resultItems.find(
            (row) => row.id == this.targetedWorkflowId,
          );
        }
      });

      this.$store.snapshotResultFilters.hasHierarchyGroups = hasHierarchyGroups;
    },
    get cachedSquads() {
      return snapshotSquadsFromCache(snapshotId);
    },
    get filtersApplied() {
      return (
        this.$store.snapshotResultFilters.tagIds.length ||
        this.$store.snapshotResultFilters.squadIds.length ||
        this.$store.snapshotResultFilters.hierarchyGroup
      );
    },
    determineSquadNamesFromSquadIds() {
      const selectedSquads = this.cachedSquads.filter(
        (squad) =>
          this.squadIds.includes(String(squad.id)) ||
          this.squadIds.includes(squad.id),
      );

      this.squadNamesFromSquadIds = selectedSquads.map((squad) => squad.name);
      // Loading directly to modal from URL cached squads are empty, so wait for event
      if (this.squadIds.length != 1) {
        this.showTeamBreakdown = true;
      } else {
        this.showTeamBreakdown = this.cachedSquads.find(
          (squad) => squad.id == this.squadIds[0],
        )?.is_parent;
      }
    },
    get visibleDrilldownTabs() {
      let filteredTabs = drilldownTabs.filter((drilldownTab) => {
        if (drilldownTab.tab == "team_breakdown") return this.showTeamBreakdown;
        // Related workflows requires at least one workflow
        if (drilldownTab.tab == "related_driver")
          return this.selectedWorkflow?.factor_id;
        // Recommendations requires an article
        if (drilldownTab.tab == "recommendations")
          return this.selectedWorkflow?.atlas_article_id;

        return true;
      });

      if (hasHierarchyGroups) {
        filteredTabs = filteredTabs.map((t) =>
          t.tab == "team_breakdown" ? { ...t, text: "Breakdown" } : t,
        );
      }

      return filteredTabs;
    },
    get tagNamesFromTagIds() {
      const selectedTags = tagOptions.filter(
        (tag) =>
          this.tagIds.includes(String(tag.id)) || this.tagIds.includes(tag.id),
      );

      return selectedTags.map((tag) => tag.name);
    },
    get showOrgComparision() {
      return (
        showOrgAvg && this.$store.snapshotResultFilters.squadIds.length > 0
      );
    },
    get showScores() {
      return this.responseCount >= minResponseThreshold;
    },
    get compareTo() {
      return this.$store.snapshotResultFilters.compareTo;
    },
    get previousSnapshotId() {
      return this.$store.snapshotResultFilters.previousSnapshotId;
    },
    get sortReverse() {
      return this.$store.snapshotResultFilters.sortReverse;
    },
    get sortBy() {
      return this.$store.snapshotResultFilters.sortBy;
    },
    get drilldownSortBy() {
      return this.$store.snapshotResultFilters.drilldownSortBy;
    },
    get drilldownSortReverse() {
      return this.$store.snapshotResultFilters.drilldownSortReverse;
    },
    get tagIds() {
      return this.$store.snapshotResultFilters.tagIds;
    },
    get squadIds() {
      return this.$store.snapshotResultFilters.squadIds;
    },
    get hasMultipleTrendValues() {
      return this?.trends?.values?.length > 1;
    },
    percentageWidthForPosition(workflow, position) {
      return `width: ${this.workflowPercentageOfResponses(
        workflow,
        position,
        "count",
      )}`;
    },
    workflowPercentageOfResponses(workflow, position) {
      if (workflow["count"] === 0) return "0%";

      return `${Math.round(
        (100 * workflow[targetPositionCountMap.get(position)]) /
          workflow["count"],
      )}%`;
    },
    targetedPositions(workflow) {
      if (!workflow) return;

      if (workflow.target_direction == "u")
        return arrayRange(0, workflow.target_position, 1);

      return arrayRange(
        workflow.target_position,
        maxBehavioralQuestions - 1,
        1,
      ).reverse();
    },
    nonTargetedPositions(workflow) {
      if (!workflow) return;

      if (workflow.target_direction == "u")
        return arrayRange(
          workflow.target_position + 1,
          maxBehavioralQuestions - 1,
          1,
        );

      return arrayRange(0, workflow.target_position - 1, 1).reverse();
    },
    percentageOfResponses(positionCount, totalFieldCount) {
      const item = this.selectedWorkflow;

      if (!item) return;

      if (item[totalFieldCount] === 0) return "0%";

      return `${Math.round(
        (100 * item[positionCount]) / item[totalFieldCount],
      )}%`;
    },
    modalTitle() {
      return this.selectedWorkflow?.name;
    },
    openDrilldownModal(selectedWorkflow) {
      // Don't show any modals if not enough responses
      if (this.responseCount < minResponseThreshold) return;
      this.drilldownModalOpen = true;
      // Defaults to overview tab when opening modal
      this.selectedDrilldownTab = "overview";
      this.targetedWorkflowId = selectedWorkflow.id;
      this.selectedWorkflow = selectedWorkflow;

      setQueryStringParam("behavioral_question_id", selectedWorkflow.id);
      setQueryStringParam("ddt", "overview");
      this.fetchTab(this.drilldownTabs[0].tab, this.drilldownTabs[0].url);
    },
    hideWorkflowDetails() {
      this.drilldownModalOpen = false;
      this.targetedWorkflowId = null;
      this.selectedWorkflow = null;
      this.selectedDrilldownTab = null;
      // Clear the data so we don't run into weird cache issues.
      this.drilldownData = {};
      this.trends = null;

      setQueryStringParam("ddt", null);
      setQueryStringParam("behavioral_question_id", null);
    },
    get workflowItems() {
      const items = this.resultItems.sort(
        sortFunc(this.sortBy, this.compareTo, {
          orgScore: this.selectedWorkflow?.org_score,
        }),
      );

      if (this.sortReverse) {
        return items.reverse();
      }

      return items;
    },
    workflowBenchmarkDiff(workflow, compareTo) {
      if (
        ["vs_50th", "vs_75th", "vs_90th"].includes(compareTo) &&
        workflow["benchmark_50"] === null
      )
        return null;
      if (workflow.score == null) return null;
      if (workflow.prev_percentage == null && compareTo == "vs_prev")
        return null;
      if (workflow.org_percentage == null && compareTo == "vs_org") return null;

      return (
        Math.round(workflow.score) -
        Math.round(workflow[workflowDiffOptionsMap.get(compareTo)])
      );
    },
    isSortOrder(order) {
      return this.sortBy == order;
    },
    isDrilldownSortOrder(order) {
      return this.drilldownSortBy == order;
    },
    changeComparison(compareTo) {
      this.$store.snapshotResultFilters.setCompareTo(compareTo);
      if (this.compareTo == "vs_prev") this.fetchAll();
      // Update any trends comparison data if it's showing
      if (this.selectedDrilldownTab == "overview" && this.drilldownModalOpen) {
        this.renderWorkflowTrendChart();
      }
    },
    changeSortBy(sortBy) {
      if (this.sortBy == sortBy) {
        this.$store.snapshotResultFilters.sortReverse = !this.sortReverse;

        setQueryStringParam("sb", sortBy);
        setQueryStringParam("sr", this.sortReverse);
        return;
      }

      this.$store.snapshotResultFilters.sortBy = sortBy;
      this.$store.snapshotResultFilters.sortReverse = false;

      setQueryStringParam("sb", sortBy);
      setQueryStringParam("sr", false);
    },
    changeDrilldownSortBy(sortBy) {
      if (this.drilldownSortBy == sortBy) {
        this.$store.snapshotResultFilters.drilldownSortReverse =
          !this.drilldownSortReverse;

        setQueryStringParam("ddsr", this.drilldownSortReverse);
        return;
      }

      this.$store.snapshotResultFilters.drilldownSortBy = sortBy;
      this.$store.snapshotResultFilters.drilldownSortReverse = false;

      setQueryStringParam("ddsb", sortBy);
      setQueryStringParam("ddsr", false);
    },
    changeSquadIds(squadIds) {
      this.$store.snapshotResultFilters.squadIds = squadIds;
      this.determineSquadNamesFromSquadIds();
      setQueryStringParam("squad_ids", squadIds);

      this.fetchAll();
    },
    changeTagIds(tagIds) {
      this.$store.snapshotResultFilters.tagIds = tagIds;
      setQueryStringParam("tag_ids", tagIds);

      this.fetchAll();
    },
    percentageOfResponsesOverview(positionCount, totalFieldCount) {
      const item = this.relatedDriver;

      if (!item) return;

      if (item[totalFieldCount] === 0) return "0%";

      return `${Math.round(
        (100 * item[positionCount]) / item[totalFieldCount],
      )}%`;
    },
    fetchAll() {
      return this.fetchResultItems();
    },
    fetchTab(tab, url) {
      if (!this.drilldownData[tab]) {
        this.tabDataLoaded = false;
      }
      // Make this smarter so if we've already fetched the tab for the SAME factor we don't fetch it again
      this.selectedDrilldownTab = tab;
      setQueryStringParam("ddt", tab);

      return fetch(`${url}${this.queryParams()}`)
        .then((resp) => resp.json())
        .then((data) => {
          this.drilldownData[tab] = { data };
          this.tabDataLoaded = true;
          this.renderTabDetails(tab, data);
        });
    },
    renderTabDetails(tab, data) {
      if (tab == "overview") {
        this.trends = data.trends[0];
        this.xStart = data.x_start;
        this.renderWorkflowTrendChart();
        this.renderWorkflowDistributionChart();
      }
      if (tab == "related_driver") {
        this.relatedDriver = data.driver;
        this.xStart = data.x_start;
        this.relatedDriverTrends = data.driver_trends;
      }
    },
    xStartLabel() {
      const date = new Date(this.xStart);
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();

      return `${month} ${year}`;
    },
    get atlasArticle() {
      if (this.drilldownData.recommendations?.data) {
        return this.drilldownData.recommendations.data.article;
      }

      return null;
    },
    queryParams() {
      const paramObj = {
        ct: this.compareTo,
        prev_ss: this.previousSnapshotId,
        squad_ids: `${this.squadIds.join(",")}`,
        tag_ids: `${this.tagIds.join(",")}`,
        behavioral_question_id: this.targetedWorkflowId,
        hg: this.$store.snapshotResultFilters.hierarchyGroup.encoded_id,
        branch: this.$store.snapshotResultFilters.branch,
      };

      return `?${objectToQueryString(paramObj)}`;
    },
    calculateItemComparison(item) {
      if (
        ["vs_50th", "vs_75th", "vs_90th"].includes(this.compareTo) &&
        item.benchmark_50 == null
      )
        return null;

      if (this.compareTo == "vs_50th") {
        return item.score - item.benchmark_50;
      } else if (this.compareTo == "vs_75th") {
        return item.score - item.benchmark_75;
      } else if (this.compareTo == "vs_90th") {
        return item.score - item.benchmark_90;
      } else if (this.compareTo == "vs_prev") {
        return item.previous_score != null
          ? item.score - item.previous_score
          : null;
      } else if (this.compareTo == "vs_org") {
        return item.score - this.selectedWorkflow?.org_score;
      }
    },
    calculateDriverComparison(item) {
      if (this.compareTo == "vs_50th") {
        return item.score - item.benchmark_50;
      } else if (this.compareTo == "vs_75th") {
        return item.score - item.benchmark_75;
      } else if (this.compareTo == "vs_90th") {
        return item.score - item.benchmark_90;
      } else if (this.compareTo == "vs_prev") {
        return item.previous_score != null
          ? item.score - item.previous_score
          : null;
      } else if (this.compareTo == "vs_org") {
        return item.score - item.org_score;
      }
    },
    get breakdownItems() {
      if (this.drilldownData.team_breakdown) {
        const items = this.drilldownData.team_breakdown.data.result_items.sort(
          sortFunc(this.drilldownSortBy, this.compareTo, {
            orgScore: this.selectedWorkflow?.org_score,
            reverse: this.drilldownSortReverse,
          }),
        );

        const fieldsWithReversibleSortFunctions = [
          "score",
          "calculatedComparison",
          "dxiComparison",
        ];

        if (
          !fieldsWithReversibleSortFunctions.includes(this.drilldownSortBy) &&
          this.drilldownSortReverse
        ) {
          return items.reverse();
        }

        return items;
      }

      return [];
    },
    workflowTrendEnd() {
      if (!this.trends) return;

      return this.trends.values[this.trends.values.length - 1].label;
    },
    renderDriverTrendChart() {
      const elChart = document.getElementById(`driver-trends-chart`);
      if (!elChart) return;

      elChart.innerHTML = null;
      const trends = this.relatedDriverTrends[0];
      const benchmark = this.compareTo;
      const minVal = Math.min(
        ...trends.values.map((v) => v.value).concat(trends.factor.benchmark_50),
      );
      const maxVal = Math.max(
        ...trends.values.map((v) => v.value).concat(trends.factor.benchmark_90),
      );

      const trendSize = trends.values.length;
      this.xEnd = trends.values[trendSize - 1]?.label;
      // Avoid using 'this' in passing in function for the tooltip callback, get the string explicitly
      const heading = trends.factor.name;

      const attrs = {
        elChart: elChart,
        startDate: this.xStart,
        endDate: trends.values[trendSize - 1]?.date,
        tooltipHtml(d, cd) {
          const params = {
            heading: heading,
            d: {
              label: d.label,
              value: d.value,
              formattedValue: d.value,
            },
          };

          if (cd) {
            params.cd = {
              label: cd.label,
              value: cd.value,
              formattedValue: cd.value,
            };
          }

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: trends.values,
      };

      if (
        benchmark &&
        (trends.factor[workflowDiffOptionsMap.get(benchmark)] ||
          benchmark === "vs_prev")
      ) {
        attrs.comparisonValues = generateComparisonValues(
          trends,
          workflowDiffOptionsMap.get(benchmark),
          "factor",
        );
      }

      if (benchmark == "vs_org") {
        attrs.comparisonValues = generateOrgComparisonValues(
          trends,
          Math.round(this.relatedDriver?.org_score),
        );
      }

      return new LineChart(attrs);
    },
    renderWorkflowTrendChart() {
      // return true
      if (!this.selectedWorkflow || !this.trends) return;
      const workflow = this.selectedWorkflow;
      const trendData = this.trends;
      const elChart = document.getElementById(`workflow-${workflow.id}-chart`);
      if (!elChart) return;
      elChart.innerHTML = null;

      const benchmark = this.compareTo;
      const minVal = Math.min(
        ...trendData.values.map((v) => v.value).concat(workflow.benchmark_50),
      );
      const maxVal = Math.max(
        ...trendData.values.map((v) => v.value).concat(workflow.benchmark_90),
      );
      const xEnd = trendData.values[trendData.values.length - 1].date;

      const attrs = {
        elChart: elChart,
        startDate: this.xStart,
        endDate: xEnd,
        tooltipHtml(d, cd) {
          const params = {
            heading: workflow.name,
            d: {
              label: d.label,
              value: d.value,
              formattedValue: `${d.value}%`,
            },
          };

          if (cd) {
            params.cd = {
              label: cd.label,
              value: cd.value,
              formattedValue: `${cd.value}%`,
            };
          }

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: trendData.values,
      };

      if (
        benchmark &&
        (workflow[workflowDiffOptionsMap.get(benchmark)] ||
          benchmark === "vs_prev")
      ) {
        attrs.comparisonValues = generateComparisonValues(
          trendData,
          workflowDiffOptionsMap.get(benchmark),
          "workflow",
        );
      }

      if (benchmark == "vs_org") {
        attrs.comparisonValues = generateOrgComparisonValues(
          trendData,
          Math.round(this.selectedWorkflow?.org_score),
        );
      }

      return new LineChart(attrs);
    },
    renderWorkflowDistributionChart() {
      if (
        !this.selectedWorkflow ||
        !this.trends ||
        !this.hasMultipleTrendValues
      )
        return;

      const workflow = this.selectedWorkflow;
      const trendData = this.trends;
      let elChart = document.getElementById(
        `workflow-${workflow.id}-distribution-chart`,
      );
      if (!elChart) return;
      elChart.innerHTML = null;

      const benchmark = this.compareTo;
      const minVal = Math.min(
        ...trendData.values.map((v) => v.value).concat(workflow.benchmark_50),
      );
      const maxVal = Math.max(
        ...trendData.values.map((v) => v.value).concat(workflow.benchmark_90),
      );
      const xEnd = trendData.values[trendData.values.length - 1].date;

      const colors = ["#D2B6F9", "#A8B4F7", "#92D1F8", "#86E7D5", "#A0EDB2"];
      let stackedAreaValues = { series: [], dates: [] };
      let options = JSON.parse(this.trends.values[0]?.options);
      let needsReversing = this.selectedWorkflow?.target_direction === "d";
      if (needsReversing) options = options.reverse();

      options.forEach((opt, idx) => {
        let indexToUse = idx;
        // Go backwards through the list of options to ensure ordering matches the distribution chart above
        if (needsReversing) indexToUse = options.length - (idx + 1);

        stackedAreaValues.series.push({
          name: opt,
          color: colors[idx],
          counts: [],
          key: `${numberToSpelling(indexToUse)}_position_count`,
        });
      });

      // Formatting the trend data to match the required format the stacked area chart needs
      this.trends.values.forEach((val) => {
        stackedAreaValues.dates.push(val.date);

        stackedAreaValues.series.forEach((breakdownValue) => {
          breakdownValue.counts.push(val.breakdown[breakdownValue.key]);
        });
      });

      // NOTE: reversing here so that the stacks matches the order of the distribution chart above
      stackedAreaValues.series = stackedAreaValues.series.reverse();

      const attrs = {
        elChart: elChart,
        startDate: this.xStart,
        endDate: xEnd,
        tooltipHtml(d, cd) {
          const params = {
            heading: new Intl.DateTimeFormat("en-US", {
              month: "short",
              year: "numeric",
            }).format(d),
            rows: cd
              .map((r) => ({
                label: r.name,
                value: r.count,
                formattedValue: Math.round(r.percentage * 100) + "%",
                color: r.color,
              }))
              .reverse(),
          };

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: stackedAreaValues,
      };

      if (
        benchmark &&
        (workflow[workflowDiffOptionsMap.get(benchmark)] ||
          benchmark === "vs_prev")
      ) {
        attrs.comparisonValues = generateComparisonValues(
          trendData,
          workflowDiffOptionsMap.get(benchmark),
          "workflow",
        );
      }

      return new StackedAreaChart(attrs);
    },
    get atlasStrategies() {
      if (this.drilldownData.recommendations?.data) {
        return this.drilldownData.recommendations.data.strategies;
      }

      return [];
    },
    fetchResultItems() {
      this.resultItemsLoaded = false;

      let url = `${baseUrl}/items`;

      const paramsObj = {
        ct: this.compareTo,
        prev_ss: this.previousSnapshotId,
        squad_ids: this.squadIds.join(","),
        tag_ids: this.tagIds.join(","),
        branch: this.$store.snapshotResultFilters.branch,
      };

      const queryParams = objectToQueryString(paramsObj);

      return fetch(`${url}?${queryParams}`)
        .then((resp) => resp.json())
        .then((data) => {
          this.resultItems = data.result_items;
          let responseCount = 0;
          data.result_items.forEach((row) => {
            if (row.total_responses > (responseCount || 0))
              responseCount = row.total_responses;
          });
          this.responseCount = responseCount;

          this.resultItemsLoaded = true;
        });
    },
    refetchTeamBreakdown() {
      const selectedItem = drilldownTabs.find(
        (t) => t.tab == this.selectedDrilldownTab,
      );

      this.fetchTab(this.selectedDrilldownTab, selectedItem.url);
    },
  };
}
